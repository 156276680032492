import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabSwitch from '../../../components/tabSwitch';
import Content from './content';

class ObserverColumn extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        type: '0',
    };

    tabChange = type => {
        this.setState({ type });
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { type } = this.state;

        const { observerTab, linkFengUrl, observerContent } = content;

        return (
            <div className={styles.wrap}>
                <Chip id="50018" type="struct" title="观察家tab" groupName="正文" content={observerTab}>
                    <TabSwitch tabChange={this.tabChange} type={type} />
                </Chip>
                <Chip id="45017" type="struct" title="观察家content" groupName="正文" content={observerContent}>
                    <Content linkFengUrl={linkFengUrl} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(ObserverColumn);
