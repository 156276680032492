import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import ExtremeTrsvel from './extremeTrsvel';
import GlobalGo from './globalGo';
import errorBoundary from '@ifeng-fe/errorBoundary';

class TravelColumn extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const {
            news3,
            jzContent,
            globalGoTab,
            globalGoContent,
            extremeTrsvelAd,
            globalGoAd,
            businessNewAd1,
            throughColumnAd1,
        } = content;

        const extremeTrsvelData = {
            news3,
            jzContent,
            globalGoTab,
            globalGoContent,
            extremeTrsvelAd,
        };

        const globalGoData = {
            globalGoTab,
            globalGoContent,
            globalGoAd,
        };

        return (
            <React.Fragment>
                <div className={styles.wrap}>
                    <ExtremeTrsvel content={extremeTrsvelData} />
                    <GlobalGo content={globalGoData} />
                </div>
                <NoSSR onSSR={<div className={styles.businessNew} />}>
                    <Ad content={businessNewAd1} styleName={styles.businessNew} />
                </NoSSR>

                <NoSSR onSSR={<div className={styles.throughColumnAd1} />}>
                    <Ad content={throughColumnAd1} styleName={styles.throughColumnAd1} />
                </NoSSR>
            </React.Fragment>
        );
    }
}

export default errorBoundary(TravelColumn);
