import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabSwitch from '../../../components/tabSwitch';
import Content from './content';
import { globalGoAndChinaChip } from '../chipConfig';

class GlobalGoAndChina extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        type: '0',
    };

    tabChange = type => {
        this.setState({ type });
    };

    /**
     * 渲染网页布局
     */
    render() {
        const { type } = this.state;
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        const { globalGoAndChinaTab, globalGoAndChinaGlGContent, globalGoAndChinaChContent, rectangleAd2 } = content;

        const globalGoAndChinaContentData = [globalGoAndChinaGlGContent, globalGoAndChinaChContent];
        const typeNum = Number(type);
        const currentContentData = globalGoAndChinaContentData[typeNum];

        const { id, title, translate } = globalGoAndChinaChip;

        console.log(id);

        return (
            <div className={styles.wrap}>
                <Chip
                    id="45037"
                    type="struct"
                    title="全球go和大美中国tab"
                    groupName="正文"
                    content={globalGoAndChinaTab}>
                    <TabSwitch tabChange={this.tabChange} type={type} />
                </Chip>

                {id.map((item, index) => {
                    return typeNum === index ? (
                        // <Chip
                        //     key={index}
                        //     id={id[typeNum]}
                        //     type="recommend"
                        //     title={title[typeNum]}
                        //     groupName="正文"
                        //     content={currentContentData}
                        //     translate={translate[typeNum]}>

                        // </Chip>
                        <Content content={currentContentData} />
                    ) : (
                        ''
                    );
                })}

                {id.map((item, index) => {
                    return (
                        <Chip
                            key={index}
                            id={id[index]}
                            type="recommend"
                            title={title[index]}
                            groupName="正文"
                            translate={translate[index]}>
                            <div className={styles[`box_${index}`]} />
                        </Chip>
                    );
                })}

                <NoSSR onSSR={<div className={styles.box} />}>
                    <Ad content={rectangleAd2} styleName={styles.box} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(GlobalGoAndChina);
