const globalGoAndChinaChip = {
    id: ['60053', '60071'],
    title: ['全球go和大美中国-全球go', '全球go和大美中国-大美中国'],
    translate: ['handleTravelGlobalGoAndChinaGlGData', 'handleTravelGlobalGoAndChinaChData'],
};

const swimsChip = {
    id: ['50021', '50022'],
    title: ['游物tab', '业界tab'],
};

export { globalGoAndChinaChip, swimsChip };
