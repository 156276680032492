import React from 'react';
import PropTypes from 'prop-types';
import { Event } from '@ifeng-fe/ui_base';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Slides from '@ifeng-fe/ui_pc_slides';
import { oneChangeTwoDimensional, handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/utils';

const event = new Event();

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        content: this.props.content.globalNewsContent,
    };

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { content } = this.state;
            const infoData = [...content];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    content: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const { globalNewsAd } = this.props.content;

            const callback = await handleAd(globalNewsAd);

            callback(globalNewsAd.data, event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            event.trigger('init');
        } catch (e) {
            console.error(e);
        }
    }

    sliderTmpl = itemOut =>
        itemOut.map((itemInner, index) => {
            return itemInner.type === 'ad' ? (
                <div ref={itemInner.ref} className={index === 3 ? styles.last : styles.slideItem} key={index} />
            ) : (
                <div className={index === 3 ? styles.last : styles.slideItem} key={itemInner.id}>
                    <div className={styles.mask} />
                    <a
                        className={styles.blockA}
                        href={itemInner.url}
                        target="_blank"
                        alt={itemInner.title}
                        title={itemInner.title}
                        rel={rel}>
                        <img src={itemInner.thumbnails} style={{ width: 235, height: 130 }} />
                    </a>
                    <div className={styles.text_wrap}>
                        <a href={itemInner.url} target="_blank" alt={itemInner.title} title={itemInner.title} rel={rel}>
                            {itemInner.title}
                        </a>
                    </div>
                </div>
            );
        });
    /**
     * 渲染网页布局
     */
    render() {
        const { content } = this.state;

        // 转化为二维数组
        const num = 4;
        let contentData = oneChangeTwoDimensional(content, num);

        const data = contentData.splice(contentData.length - 1, 1);

        contentData = [...data, ...contentData];

        const sliderConfig = {
            arrows: true,
            autoplay: false,
            dots: false,
            direction: 'forward',
            pauseOnHover: true,
            // autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 1,
            // unitSize: 1000,
            // speed: 300,
            infinite: true,
            sliderTmpl: this.sliderTmpl,
        };

        return (
            <div className={styles.ActionLeague}>
                <Slides content={contentData} config={sliderConfig} />
            </div>
        );
    }
}

export default errorBoundary(Content);
