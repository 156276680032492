import React from 'react';
import PropTypes from 'prop-types';
import { Event } from '@ifeng-fe/ui_base';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/utils';

class ListItem extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        index: PropTypes.number,
        ad: PropTypes.object,
    };

    state = {
        content: this.props.content,
    };

    event = new Event();

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { content } = this.state;
            const infoData = [...content];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    content: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const ad = this.props.ad;

            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: function(data, event, insert) {
            //         // event 为传入的 event 事件对象，用于广告方绑定事件
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', function(data) { // data 为我们触发方法时传参值
            //             const insertArr = [];

            //             const replaceArr = [1];

            //             insert(insertArr, replaceArr, function(dom) {
            //                 // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置
            //                 // dom 为自定义形式插入的广告位dom，广告方可随意操作
            //                 let box1 = document.createElement("div");
            //                 dom[1].appendChild(box1)
            //             })
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            this.event.trigger('init');
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { index } = this.props;
        const { content } = this.state;

        const itemDom = content.map((item, idx) => {
            return item.type === 'ad' ? (
                <li key={idx} ref={item.ref} />
            ) : (
                <li key={idx}>
                    <a href={item.url} title={item.title} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </li>
            );
        });

        return <ul className={index === 2 ? styles.last : styles.ul}>{itemDom}</ul>;
    }
}

export default errorBoundary(ListItem);
