import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabNav from '../../../../components/tabNav';
import Content from './content';

class GlobalGo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { globalGoTab, globalGoContent, globalGoAd } = content;

        return (
            <div className={styles.wrap}>
                <Chip id="50016" type="struct" title="全球goTab" groupName="正文" content={globalGoTab}>
                    <TabNav />
                </Chip>
                <Chip
                    id="60051"
                    type="recommend"
                    title="全球goContent"
                    groupName="正文"
                    content={globalGoContent}
                    translate="handleTravelGlobalGoData">
                    <Content />
                </Chip>
                <NoSSR onSSR={<div className={styles.box} />}>
                    <Ad content={globalGoAd} styleName={styles.box} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(GlobalGo);
