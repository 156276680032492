import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class TabSwitch extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    state = {
        ...this.props,
    };

    tabTimer = null;

    hoverLi = e => {
        e.stopPropagation();
        clearTimeout(this.tabTimer);
        const type = e.currentTarget.getAttribute('data-type');

        this.tabTimer = setTimeout(() => {
            if (type === null && type === this.state.type) return;
            this.setState({ type });
            this.state.tabChange(type);
        }, 200);
    };
    componentWillUnmount() {
        this.tabTimer = null;
    }

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const content = this.props.content;

        const { type } = this.state;
        const typeNum = Number(type);

        const listDom = content.filter(item => item.isShow === undefined || item.isShow).map((item, index) => (
            <li
                key={index}
                data-type={index}
                onMouseEnter={this.hoverLi}
                className={typeNum === index ? styles.current : ''}>
                <a href={item.url} target="_blank" rel={rel}>
                    {item.title}
                </a>
            </li>
        ));

        return <ul className={styles.wrap}>{listDom}</ul>;
    }
}

export default errorBoundary(TabSwitch);
