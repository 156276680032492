import React from 'react';
import PropTypes from 'prop-types';
import { Event } from '@ifeng-fe/ui_base';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/utils';
import Slides from '@ifeng-fe/ui_pc_slides';

const event = new Event();

class Banner extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    state = {
        content: this.props.content,
    };

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { content } = this.state;
            const infoData = [...content];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    content: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const { ad } = this.props;

            const callback = await handleAd(ad);

            callback(ad.data, event, this.insert);
        } catch (e) {
            console.error(e);
        }
        try {
            event.trigger('init');
        } catch (e) {
            console.error(e);
        }
    }

    sliderTmpl = (item, index) => {
        return item.type === 'ad' ? (
            <div key={index} ref={item.ref} className={styles.slideItem} />
        ) : (
            <div className={styles.slideItem} key={index}>
                <div className={styles.mask} />
                <a
                    className={styles.blockA}
                    href={item.url}
                    target="_blank"
                    alt={item.title}
                    title={item.title}
                    rel={rel}>
                    <img src={item.thumbnails} style={{ width: '100%', height: 380 }} />
                </a>
                <div className={styles.text_wrap}>
                    <div className={styles.title_wrap}>
                        <a href={item.url} target="_blank" alt={item.title} title={item.title} rel={rel}>
                            {item.title}
                        </a>
                    </div>
                    <p>{item.summary}</p>
                </div>
            </div>
        );
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.state;

        const sliderConfig = {
            arrows: 'hover',
            autoplay: true,
            dots: true,
            dotsAction: 'click',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            // unitSize: 1000,
            speed: 300,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl: this.sliderTmpl,
        };

        return (
            <div className={styles.ActionLeague}>
                <Slides content={content} config={sliderConfig} />
            </div>
        );
    }
}

export default errorBoundary(Banner);
