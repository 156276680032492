import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Banner from './banner';
import TravelNews from './travelNews';
import FocusNews from './focusNews';
import TravelColumn from './travelColumn';
import GlobalNews from './globalNews';
import ObserverColumn from './observerColumn';
import GlobalGoAndChina from './globalGoAndChina';
import TravelWay from './travelWay';
import Addicted from './addicted';
import Appreciation from './appreciation';
import Swims from '../../components/swims';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const {
            bannerPic,
            news1,
            news2,
            news3,
            jzContent,
            globalGoTab,
            globalGoContent,
            observerTab,
            linkFengUrl,
            observerContent,
            globalNewsTab,
            globalNewsContent,
            globalGoAndChinaTab,
            globalGoAndChinaGlGContent,
            globalGoAndChinaChContent,
            addictedTab,
            addictedConent,
            appreciationTab,
            appreciationTabConent,
            travelWayTab,
            travelWayOnWay,
            travelWaySelfDrive,
            travelWayLiner,
            travelWayFly,
            coldKnowledgeTab,
            coldKnowledgeContent,
            swimsTab,
            swimsConent,
            industryTab,
            industryConent,
            globalGoAd,
            aslideAd,
            travelEntryAd4,
            travelEntryAd5,
            travelEntryAd6,
            businessNewAd1,
            businessNewAd2,
            businessNewAd3,
            businessNewAd4,
            businessNewAd5,
            businessNewAd6,
            extremeTrsvelAd,
            throughColumnAd1,
            globalNewsAd,
            rectangleAd2,
            throughColumnAd2,
            travelWayAd,
            coldKnowledgeAd,
            rectangleAd3,
            throughColumnAd3,
            swimsAd1,
            swimsAd2,
        } = content;

        const FocusNewsAdData = {
            travelEntryAd4,
            travelEntryAd5,
            travelEntryAd6,
        };

        const TrsvelData = {
            news3,
            jzContent,
            globalGoTab,
            globalGoContent,
            extremeTrsvelAd,
            globalGoAd,
            businessNewAd1,
            businessNewAd2,
            businessNewAd3,
            businessNewAd4,
            businessNewAd5,
            businessNewAd6,
            throughColumnAd1,
        };

        const observerData = {
            observerTab,
            linkFengUrl,
            observerContent,
        };

        const globalNewsData = {
            globalNewsTab,
            globalNewsContent,
            globalNewsAd,
        };

        const globalGoAndChinaData = {
            globalGoAndChinaTab,
            globalGoAndChinaGlGContent,
            globalGoAndChinaChContent,
            rectangleAd2,
        };

        const TravelWayData = {
            travelWayTab,
            travelWayOnWay,
            travelWaySelfDrive,
            travelWayLiner,
            travelWayFly,
            coldKnowledgeTab,
            coldKnowledgeContent,
            travelWayAd,
            coldKnowledgeAd,
            rectangleAd3,
            throughColumnAd3,
        };

        const addictedData = {
            addictedTab,
            addictedConent,
        };

        const appreciationData = {
            appreciationTab,
            appreciationTabConent,
            throughColumnAd2,
        };

        const SwimsData = {
            swimsTab,
            swimsConent,
            type: '0',
            swimsAd1,
        };

        const industryData = {
            swimsTab: industryTab,
            swimsConent: industryConent,
            type: '1',
            swimsAd2,
        };

        return (
            <div className={styles.travel_main}>
                <Chip
                    id="60048"
                    type="recommend"
                    title="焦点图"
                    groupName="正文"
                    content={bannerPic}
                    translate="handleTravelBannerPicData">
                    <Banner ad={aslideAd} />
                </Chip>
                <Chip
                    id="60049"
                    type="recommend"
                    title="旅讯"
                    groupName="正文"
                    content={news1}
                    translate="handleTravelNewsData">
                    <TravelNews />
                </Chip>
                <Chip
                    id="60050"
                    type="recommend"
                    title="要闻"
                    groupName="正文"
                    content={news2}
                    translate="handleTravelFocusNewsData">
                    <FocusNews ad={FocusNewsAdData} />
                </Chip>

                <TravelColumn content={TrsvelData} />

                <GlobalNews content={globalNewsData} />

                <ObserverColumn content={observerData} />

                <GlobalGoAndChina content={globalGoAndChinaData} />

                <Addicted content={addictedData} />

                <Appreciation content={appreciationData} />

                <TravelWay content={TravelWayData} />

                {/* 游物 */}
                <Swims content={SwimsData} />

                {/* 业界 */}
                <Swims content={industryData} />
            </div>
        );
    }
}

export default errorBoundary(Content);
