import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from './header';
import Navigation from './navigation';
import ChildNav from './childNav';
import Content from './content';
import InnerHtml from '../components/innerHtml';
import Footer from '../components/footer';
import ToTop from '../components/toTop';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const {
            content: {
                nav,
                search,
                logo,
                logoAd,
                navigation,
                childNav,
                bannerPic,
                news1,
                news2,
                news3,
                jzContent,
                globalGoTab,
                globalGoContent,
                observerTab,
                linkFengUrl,
                observerContent,
                globalNewsTab,
                globalNewsContent,
                globalGoAndChinaTab,
                globalGoAndChinaGlGContent,
                globalGoAndChinaChContent,
                addictedTab,
                addictedConent,
                appreciationTab,
                appreciationTabConent,
                travelWayTab,
                travelWayOnWay,
                travelWaySelfDrive,
                travelWayLiner,
                travelWayFly,
                coldKnowledgeTab,
                coldKnowledgeContent,
                swimsTab,
                swimsConent,
                industryTab,
                industryConent,
                cooperation,
                footer,
                topAd,
                aslideAd,
                travelEntryAd4,
                travelEntryAd5,
                travelEntryAd6,
                bottomAd,
                extremeTrsvelAd,
                globalGoAd,
                businessNewAd1,
                throughColumnAd1,
                globalNewsAd,
                rectangleAd2,
                throughColumnAd2,
                travelWayAd,
                coldKnowledgeAd,
                rectangleAd3,
                throughColumnAd3,
                swimsAd1,
                swimsAd2,
                flAd,
                floatAd,
                bottomAd01,
            },
        } = this.props;

        const headerData = {
            nav,
            search,
            topAd,
            logo,
            logoAd,
        };

        const contentData = {
            bannerPic,
            news1,
            news2,
            news3,
            jzContent,
            globalGoTab,
            globalGoContent,
            observerTab,
            linkFengUrl,
            observerContent,
            globalNewsTab,
            globalNewsContent,
            globalGoAndChinaTab,
            globalGoAndChinaGlGContent,
            globalGoAndChinaChContent,
            addictedTab,
            addictedConent,
            appreciationTab,
            appreciationTabConent,
            travelWayTab,
            travelWayOnWay,
            travelWaySelfDrive,
            travelWayLiner,
            travelWayFly,
            coldKnowledgeTab,
            coldKnowledgeContent,
            swimsTab,
            swimsConent,
            industryTab,
            industryConent,
            aslideAd,
            travelEntryAd4,
            travelEntryAd5,
            travelEntryAd6,
            extremeTrsvelAd,
            globalGoAd,
            businessNewAd1,
            throughColumnAd1,
            globalNewsAd,
            rectangleAd2,
            throughColumnAd2,
            travelWayAd,
            coldKnowledgeAd,
            rectangleAd3,
            throughColumnAd3,
            swimsAd1,
            swimsAd2,
        };

        return (
            <div className={styles.content}>
                <Header content={headerData} />
                <Chip id="45016" type="struct" title="顶部导航" groupName="导航栏" content={navigation}>
                    <Navigation />
                </Chip>
                {Array.isArray(childNav) && childNav.length > 0 ? (
                    <Chip id="170038" type="struct" title="子导航" groupName="导航栏" content={childNav}>
                        <ChildNav />
                    </Chip>
                ) : (
                    ''
                )}

                <Content content={contentData} />

                <div className={styles.feet}>
                    <div className={styles.col_wbf}>
                        <div className={styles.col_w1000}>
                            <div className={styles.space01}>&nbsp;</div>
                            <NoSSR>
                                <Chip
                                    id="30043"
                                    type="static"
                                    title="底部合作"
                                    groupName="content"
                                    content={cooperation}>
                                    <InnerHtml />
                                </Chip>
                            </NoSSR>

                            <NoSSR onSSR={<div className={styles.box} />}>
                                <Ad content={bottomAd} styleName={styles.box} />
                            </NoSSR>
                            <Chip id="20036" type="struct" title="底部公用版权" groupName="底部" content={footer}>
                                <Footer />
                            </Chip>
                        </div>
                    </div>
                </div>

                {/* 浮动对联广告 */}
                <NoSSR onSSR={<div className={styles.fl} />}>
                    <Ad content={floatAd} styleName={styles.fl} />
                </NoSSR>
                <NoSSR onSSR={<div className={styles.fr} />}>
                    <Ad content={floatAd} styleName={styles.fr} />
                </NoSSR>

                {/* 浮动广告 */}
                <NoSSR onSSR={<div className={styles.float} />}>
                    <Ad content={flAd} styleName={styles.float} />
                </NoSSR>

                {/* 底纹 */}
                <NoSSR onSSR={<div />}>
                    <Ad content={bottomAd01} />
                </NoSSR>

                <ToTop />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
