import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import InnerHtml from '../../../../components/innerHtml';
import Content from './content';

class ColdKnowledge extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { coldKnowledgeTab, coldKnowledgeContent, coldKnowledgeAd, rectangleAd3 } = content;

        const coldKnowledgeData = {
            coldKnowledgeContent,
            coldKnowledgeAd,
        };

        return (
            <div className={styles.wrap}>
                <NoSSR>
                    <Chip id="30040" type="static" title="旅游冷知识tab" groupName="正文" content={coldKnowledgeTab}>
                        <InnerHtml />
                    </Chip>
                </NoSSR>

                <Content content={coldKnowledgeData} />
                <NoSSR onSSR={<div className={styles.box} />}>
                    <Ad content={rectangleAd3} styleName={styles.box} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(ColdKnowledge);
