import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const content = this.props.content;

        const listDom = content.map(item => (
            <div key={item.id} className={styles.list}>
                <a href={item.url} target="_blank" rel={rel}>
                    <img src={item.thumbnails} style={{ width: '320px', height: '212px' }} />
                </a>
                <p>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title}
                    </a>
                </p>
                <div className={styles.qqg}>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.summary}
                    </a>
                </div>
            </div>
        ));

        return <div className={styles.wrap}>{listDom}</div>;
    }
}

export default errorBoundary(Content);
