import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabSwitch from '../../../../components/tabSwitch';
import Content from './content';

class ExtremeTrsvel extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        type: '0',
    };

    tabChange = type => {
        this.setState({ type });
    };

    /*
        渲染网页布局
    */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { type } = this.state;

        const { news3, jzContent, extremeTrsvelAd } = content;

        const jzContentData = {
            jzContent,
            extremeTrsvelAd,
        };

        return (
            <div className={styles.wrap}>
                <div className={styles.tabSwitch}>
                    <Chip id="50015" type="struct" title="极致之旅tab" groupName="正文" content={news3}>
                        <TabSwitch tabChange={this.tabChange} type={type} />
                    </Chip>
                </div>

                <Content content={jzContentData} />
            </div>
        );
    }
}

export default errorBoundary(ExtremeTrsvel);
