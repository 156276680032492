import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { logo, logoAd },
        } = this.props;

        return (
            <React.Fragment>
                <a href={logo.url} target="_blank" rel={rel}>
                    <Chip id="50013" type="struct" title="Logo" groupName="头部" content={logo}>
                        <img src={logo.src} alt={logo.title} width={logo.width} height={logo.height} />
                    </Chip>
                </a>
                <div className={styles.ad_1}>
                    <Chip id="50014" type="struct" title="LogoAd" groupName="头部" content={logoAd}>
                        <img src={logoAd.src} width={logoAd.width} height={logoAd.height} />
                    </Chip>
                </div>
            </React.Fragment>
        );
    }
}

export default errorBoundary(Logo);
