import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabNav from '../../../components/tabNav';
import Content from './content';

class Addicted extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { addictedTab, addictedConent } = content;

        return (
            <div className={styles.wrap}>
                <Chip id="50019" type="struct" title="嗜住" groupName="正文" content={addictedTab}>
                    <TabNav />
                </Chip>

                <Content content={addictedConent} />
            </div>
        );
    }
}

export default errorBoundary(Addicted);
