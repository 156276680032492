import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabSwitch from '../../../components/tabSwitch';
import Content from './content';
import ColdKnowledge from './coldKnowledge';

class TravelWay extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        type: '0',
    };

    tabChange = type => {
        this.setState({ type });
    };

    /**
     * 渲染网页布局
     */
    render() {
        const { type } = this.state;
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        const {
            travelWayTab,
            travelWayOnWay,
            travelWaySelfDrive,
            travelWayLiner,
            travelWayFly,
            coldKnowledgeTab,
            coldKnowledgeContent,
            travelWayAd,
            coldKnowledgeAd,
            rectangleAd3,
            throughColumnAd3,
        } = content;

        const TravelWayData = [travelWayOnWay, travelWaySelfDrive, travelWayLiner, travelWayFly];

        const coldKnowledgeData = {
            coldKnowledgeTab,
            coldKnowledgeContent,
            coldKnowledgeAd,
            rectangleAd3,
        };

        const typeNum = Number(type);
        const currentContentData = TravelWayData[typeNum];

        return (
            <React.Fragment>
                <div className={styles.wrap}>
                    <Chip id="45038" type="struct" title="旅游方式tab" groupName="正文" content={travelWayTab}>
                        <TabSwitch tabChange={this.tabChange} type={type} />
                    </Chip>

                    <Content content={currentContentData} ad={travelWayAd} typeNum={typeNum} />

                    <ColdKnowledge content={coldKnowledgeData} />
                </div>
                <NoSSR onSSR={<div className={styles.box} />}>
                    <Ad content={throughColumnAd3} styleName={styles.box} />
                </NoSSR>
            </React.Fragment>
        );
    }
}

export default errorBoundary(TravelWay);
