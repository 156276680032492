import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class TravelNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    // 截取年月日
    sliceYMD = newsTime => {
        return newsTime.slice(0, 11);
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { title, summary, url, newsTime, thumbnails } = content.length > 0 ? content[0] : {};

        return (
            <div className={styles.wrap}>
                <div className={styles.fhlx}>
                    <div className={styles.mr24}>
                        <a href={url} target="_blank" rel={rel}>
                            <img src={thumbnails} className={styles.img} />
                        </a>
                    </div>
                    <div className={styles.lx_word}>
                        <p>
                            <span>{this.sliceYMD(newsTime)}</span>
                        </p>
                        <h2>
                            <a href={url} target="_blank" rel={rel}>
                                {title}
                            </a>
                        </h2>
                        <span>{summary}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(TravelNews);
