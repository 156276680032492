import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { oneChangeTwoDimensional } from '@ifeng-fe/container_channel/common/client/winter/utils/utils';
import ListItem from './listItem';

class FocusNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    getAd = index => {
        const { travelEntryAd4, travelEntryAd5, travelEntryAd6 } = this.props.ad;

        const adList = [travelEntryAd4, travelEntryAd5, travelEntryAd6];

        return adList[index];
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         *  组件分发数据
         */
        const { content } = this.props;

        const num = 2;
        const listData = oneChangeTwoDimensional(content, num);
        const listDom = listData.map((item, index) => (
            <ListItem key={index} index={index} content={item} ad={this.getAd(index)} />
        ));

        return <div className={styles.lx_list}>{listDom}</div>;
    }
}

export default errorBoundary(FocusNews);
