import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabNav from '../../../components/tabNav';
import Content from './content';

class Addicted extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { appreciationTab, appreciationTabConent, throughColumnAd2 } = content;

        return (
            <div className={styles.wrap}>
                <Chip id="50020" type="struct" title="赏味tab" groupName="正文" content={appreciationTab}>
                    <TabNav />
                </Chip>

                <Content content={appreciationTabConent} />
                <NoSSR onSSR={<div className={styles.throughColumnAd2} />}>
                    <Ad content={throughColumnAd2} styleName={styles.throughColumnAd2} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(Addicted);
