import React from 'react';
import PropTypes from 'prop-types';
import { Event } from '@ifeng-fe/ui_base';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/utils';

const event = new Event();

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
        typeNum: PropTypes.number,
    };

    state = {
        content: this.props.content,
    };

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { content } = this.state;
            const infoData = [...content];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    content: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const { ad } = this.props;

            const callback = await handleAd(ad);

            callback(ad.data, event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            event.trigger('init');
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const propsContent = this.props.content;
        const stateContent = this.state.content;

        const { typeNum } = this.props;

        const newContent = typeNum === 0 || typeNum === 1 ? stateContent : propsContent;

        const listDom =
            newContent &&
            newContent.length > 0 &&
            newContent.map(item => {
                return item.type === 'ad' ? (
                    <div ref={item.ref} key={item.id} className={styles.list} />
                ) : (
                    <div key={item.id} className={styles.list}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnails} style={{ width: '320px', height: '178px' }} />
                        </a>
                        <h3>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        </h3>
                        <p>
                            <span>{item.summary}</span>
                        </p>
                    </div>
                );
            });

        return <div className={styles.wrap}>{listDom}</div>;
    }
}

export default errorBoundary(Content);
