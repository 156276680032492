import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class TabNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    render() {
        const { content } = this.props;

        const { more, nav } = content;

        const navItem = nav.length > 0 ? nav[0] : {};

        return (
            <div className={`${styles.title} ${styles.mb9}`}>
                <div className={`${styles.title_02} ${styles.mb9}`}>
                    {more.isShow && more.isShow === 'false' ? (
                        ''
                    ) : (
                        <a href={more.url} target="_blank" rel={rel} className={styles.more_03} />
                    )}
                    <a href={navItem.url} target="_blank" rel={rel}>
                        {navItem.title}
                    </a>
                </div>
            </div>
        );
    }
}

export default errorBoundary(TabNav);
