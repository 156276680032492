import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        linkFengUrl: PropTypes.string,
    };

    // 跳转大凤号老师列表
    linkFeng = (url, replaceID) => {
        return url && url.replace(/wemediaId/, replaceID);
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content, linkFengUrl } = this.props;

        const listDom = content.map((item, index) => (
            // 由于数据wemediaId是一样的暂时加个index
            <div key={item.wemediaId + index} className={styles.list}>
                <img src={item.background} className={styles.img} />
                <div className={styles.bg04} />
                <div className={styles.lv_pic}>
                    <NoSSR>
                        <a href={this.linkFeng(linkFengUrl, item.wemediaId)} target="_blank" rel={rel}>
                            <img src={item.profilePicture} />
                        </a>
                    </NoSSR>
                </div>

                <div className={styles.lv_word}>
                    <h3>
                        <NoSSR>
                            <a href={this.linkFeng(linkFengUrl, item.wemediaId)} target="_blank" rel={rel}>
                                {item.name}
                            </a>
                        </NoSSR>
                    </h3>
                    <div className={styles.lv_map}>{item.location ? item.location : ''}</div>
                    <p>{item.summary}</p>
                </div>
            </div>
        ));

        return <div className={styles.wrap}>{listDom}</div>;
    }
}

export default errorBoundary(Content);
