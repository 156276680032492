import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TabNav from '../tabNav';
import Content from './content';
import { swimsChip } from '../../layout/content/chipConfig';

class Swims extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { swimsTab, swimsConent, type, swimsAd1, swimsAd2 } = content;

        const typeNum = Number(type);
        const { id, title } = swimsChip;

        const conentData = {
            swimsConent,
            swimsAd1,
            swimsAd2,
            typeNum,
        };

        return (
            <div className={styles.wrap}>
                <Chip id={id[typeNum]} type="struct" title={title[typeNum]} groupName="正文" content={swimsTab}>
                    <TabNav />
                </Chip>

                <Content content={conentData} />
            </div>
        );
    }
}

export default errorBoundary(Swims);
